<template>
  <div>
    <h1>TEST</h1>

    <PrintDocument80mm
      :key="printKey"
      :docTypeId="docTypeId"
      :type="docType"
      :data="dataPrint.main_bill"
      :data2="dataPrint.split_bill"
      ref="printDocument"
      :disabled="false"
      :firstCreated="true"
    ></PrintDocument80mm>
    <v-btn @click="print()"> print </v-btn>
  </div>
</template>

<script>
import PrintDocument80mm from '@/components/PrintDocument/printDocument80mm.vue';

export default {
  components: {
    PrintDocument80mm,
  },

  data() {
    return {
      docType: 'ใบเสนอราคา',
      docTypeId: 1,
      dataPrint: {
        main_bill: null,
        split_bill: null,
      },
      printKey: 1,
    };
  },

  methods: {
    async print() {
      let id = 'DOC20230516102316508851axx';
      let body = {
        token: this.$jwt.sign({ document_id: id }, this.$privateKey, {
          noTimestamp: true,
        }),
      };
      await this.$axios
        .post(`${this.$baseUrl}/document/print_document_data`, body)
        .then(async (res) => {
          this.dataPrint = res.result;
          setTimeout(() => {
            this.$refs.printDocument.print();
            console.log(this.dataPrint);
          }, 500);
        })
        .catch((err) => {
          console.log('err', err);
        });
    },
  },
};
</script>

<style>
</style>